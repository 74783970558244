<template>
  <div :class="className" style="text-decoration: none;" @click="$router.push(route)" :style="[coming_soon ? { cursor: 'default', backgroundColor: '#f8f8f8' } : null]">
    <img :src="img">
    <h3>{{ title }}</h3>
    <!-- <p v-if="title == 'Go To' || title == 'Scan and Go'">{{ desc }}</p> -->
    <span class="coming_soon" v-if="coming_soon"></span>
  </div>
</template>

<script setup>
    import { ref, toRefs } from 'vue';
    const props = defineProps({ 
      route: String, 
      coming_soon: Boolean, 
      img: String,
      title: String,
      className: String,
    });
    const {route,coming_soon,img,title,className} = toRefs(props);

    
</script>

<style lang="scss" scoped>
</style>