<template>
  <PreLoader :show="up"></PreLoader>
    <main class="main_v8 home">
        <h2>Wave7 Home</h2>
        <div class="boeing_home">
          <template v-for = "(tile, index) in tiles" :key="index">
            <navtile v-if="tile.tiletype=='navtile'" :class="tile.className"
              :route = "tile.route" 
              :coming_soon = "tile.coming_soon" 
              :img = "tile.img" 
              :title = "tile.title"
              :desc = "tile.desc"
              :className="tile.className" >
            </navtile>
          </template>
        </div>
    </main>
</template>

<script setup>
    import { ref, inject } from 'vue';
    import navtile from '../components/hometiles/navtile.vue';
    const apiPath = inject('apiPath');
    let isarchive = inject('isarchive');
    if(isarchive==undefined) isarchive="false";
    let tiles = ref([]);
    if(isarchive=="false") {
      tiles = [{ 
              "tiletype":"navtile",
              "route":"/select",
              "coming_soon":false,
              "img":"img/boeing/home/goto.svg",
              "title":"Go To",
              // AMOUNT OF PAGES BELOW
              // "desc":"## Locations",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/",
              "coming_soon":true,
              "img":"img/boeing/home/qrcode.svg",
              "title":"Scan and Go",
              // AMOUNT OF PAGES BELOW
              // "desc":"## Locations",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/",
              "coming_soon":true,
              "img":"img/boeing/home/favorites.svg",
              "title":"Favorites",
              "group":"1",
              "className":"fulltile"
            },{ 
              "tiletype":"navtile",
              "route":"/globalops",
              "coming_soon":false,
              "img":"img/boeing/home/globalops.svg",
              "title":"Global Operations",
              "group":"1",
              "className":"fulltile"
            },{ 
              "tiletype":"navtile",
              "route":"/operationsmonitoring",
              "coming_soon":false,
              "img":"img/boeing/home/operationsm.svg",
              "title":"Operations Monitoring",
              "group":"1",
              "className":"fulltile"
            },{ 
              "tiletype":"navtile",
              "route":"/datatrending",
              "coming_soon":false,
              "img":"img/boeing/home/datatrending.svg",
              "title":"Data Trending",
              "group":"1",
              "className":"fulltile"
            },{ 
              "tiletype":"navtile",
              "route":"/MtsMonitoring",
              "coming_soon":false,
              "img":"img/boeing/home/mts.svg",
              "title":"MTS Monitoring",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/LclMonitoring",
              "coming_soon":false,
              "img":"img/boeing/home/lcl.svg",
              "title":"LCL Monitoring",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/alarms",
              "coming_soon":false,
              "img":"img/boeing/home/alarmc.svg",
              "title":"Alarm Configuration",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/alarmsearch",
              "coming_soon":false,
              "img":"img/boeing/home/alarms.svg",
              "title":"Alarm Search",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/fleet",
              "coming_soon":false,
              "img":"img/boeing/home/fleet.svg",
              "title":"Fleet Management",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/maintenanceadmin",
              "coming_soon":false,
              "img":"img/boeing/home/operationsh.svg",
              "title":"Operations History",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/dlap",
              "coming_soon":false,
              "img":"img/boeing/home/dlap.svg",
              "title":"DLAP",
              "group":"1",
              "className":"fulltile"
            }]
    } else {
      tiles = [{ 
              "tiletype":"navtile",
              "route":"/archiver",
              "coming_soon":false,
              "img":"img/boeing/home/favorites.svg",
              "title":"Archive Manager",
              "group":"1",
              "className":"fulltile"
            },{ 
              "tiletype":"navtile",
              "route":"/datatrending",
              "coming_soon":false,
              "img":"img/boeing/home/datatrending.svg",
              "title":"Data Trending",
              "group":"1",
              "className":"fulltile"
            },{ 
              "tiletype":"navtile",
              "route":"/alarmsearch",
              "coming_soon":false,
              "img":"img/boeing/home/alarms.svg",
              "title":"Alarm Search",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/maintenanceadmin",
              "coming_soon":false,
              "img":"img/boeing/home/operationsh.svg",
              "title":"Operations History",
              "group":"1",
              "className":"halftile"
            },{ 
              "tiletype":"navtile",
              "route":"/dlap",
              "coming_soon":false,
              "img":"img/boeing/home/dlap.svg",
              "title":"DLAP",
              "group":"1",
              "className":"fulltile"
            }]
    }
   
    
    
    // fetch('https://wrp.wave7.co/uploads/tempback.php',{
    //     headers: { 'Content-type': 'application/json' },
    //   }).then((res) => res.json()).then((response) => {
    //     console.log("RESPONSE",response);
    //     tiles.value = response;
    //     console.log(tiles.value,'tiles.value')
    //   }).catch((error) => {
    //     console.log('Landing Page fetch problem: \n', error);
    //   });
</script>

<style lang="scss">

.home {
  bottom: 0px;
  >h2{
    color: #252525;
    margin: 32px 0;
    font-size: 1.53rem;
    font-weight: 600;
    line-height: 40px;
  }
}
.boeing_home{
  display: grid;
  grid-gap: 16px;
  width: 100%;
  height: calc(100% - 124px);
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "a a a a c c c c d d d d e e e e f f f f"
    "b b b b c c c c d d d d e e e e f f f f"
    "g g g g g i i i i i k k k k k m m m m m"
    "h h h h h j j j j j l l l l l m m m m m";
  >div{
    display: flex;
    overflow: hidden;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
    border-radius: 16px;
    padding-left: 32px;
    position: relative;
    box-sizing: border-box;
    img {
      height: 60px;
      width: 60px;
      position: absolute;
      left: 30px;
      top: calc(50% - 30px);
    }
    h3{
      margin-bottom: 0px;
      margin-top: 0px;
      font-weight: 600;
    }
    p{
      margin: 0px;
    }
    .coming_soon {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      // background-image: linear-gradient(45deg, #d9e9e282 25%, transparent 25%, transparent 50%, #d9e9e282 50%, #d9e9e282 75%, transparent 75%, #fff), url(../../public/img/boeing/home/coming_soon.svg);
      // background-image: linear-gradient(45deg, #d9e9e282 25%, transparent 25%, transparent 50%, #d9e9e282 50%, #d9e9e282 75%, transparent 75%, #fff);
      background-image: url(../../public/img/boeing/home/coming_soon.svg);
      background-size: 79px 54px;
      background-repeat: no-repeat;
      background-position: right 5px top 5px;
      // align-content: center;
      text-align: right;
      padding-right: 10px;
      padding-top: 10px;
      // background-color: #d9e9e2;
      color: #006C3E;
      font-size: 0.75rem;
      // line-height: 32px;
      // padding: 0 16px;
      // border-radius: 8px;
      text-transform: uppercase;
      font-weight: 600;
      @media screen and (min-width: 645px) and (max-width: 1220px){
        background-size: 55px 35px;
      }
      // font-weight: 500;
      // @media screen and (min-width: 481px) and (max-width: 900px){
      //   top: 16px;
      //   right: 16px;
      // }
    }
  }
  div:nth-child(1){
    grid-area: a;
  }
  div:nth-child(2){
    grid-area: b;
  }
  div:nth-child(3){
    grid-area: c;
  }
  div:nth-child(4){
    grid-area: d;
  }
  div:nth-child(5){
    grid-area: e;
  }
  div:nth-child(6){
    grid-area: f;
  }
  div:nth-child(7){
    grid-area: g;
  }
  div:nth-child(8){
    grid-area: h;
  }
  div:nth-child(9){
    grid-area: i;
  }
  div:nth-child(10){
    grid-area: j;
  }
  div:nth-child(11){
    grid-area: k;
  }
  div:nth-child(12){
    grid-area: l;
  }
  div:nth-child(13){
    grid-area: m;
  }
  @media screen and (min-width: 901px) and (max-width: 3840px){
  // THOSE SETTIGS FOR THE HALF ITEM
    .halftile{
      display: flex;
      padding: 0 10px 0 0;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      padding-left: 120px;
      position: relative;

      // justify-content: end;
      // padding-left: 30px;
      // h3{
      //   margin-bottom: 30px;
      // }
      img{
        top: 50%;
        transform: translateY(-50%);
      }
      @media screen and (max-width: 1400px){
        padding-left: 90px;
        // h3{
        //   margin-bottom: 15px;
        // }
        img{
          height: 60px;
          width: 60px;
          // top: 15px;
          left: 15px;
        }
        @media screen and (max-width: 1220px){
          padding-left: 70px;
          h3{
            font-size: 1rem;
          }
          img {
            height: 40px;
            width: 40px;
          }
        }
        @media screen and (max-height: 645px) and (orientation: landscape){
          justify-content: center;
          h3{
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 900px){
    .halftile{
      display: flex;
      padding-left: 70px;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      img{
        width: 40px;
        left: 20px;
      }
    }
  }
  // FOR THE FULL ITEM
  .fulltile{
    display: flex;
    padding: 0 10px 0 0;
    flex-direction: column;
    justify-content: end;
    position: relative;
    h3{
      margin: 0 0 30px 30px;
      @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
        margin: 0 0 20px 20px;
      }
    }
    img {
      top: 30px;
    }
    @media screen and (max-width: 1400px){
      h3{
        margin: 0 0 15px 15px;
      }
      img {
        height: 60px;
        width: 60px;
        top: 15px;
        left: 15px;
      }
      @media screen and (min-width: 900px) and (max-width: 1220px){
        h3{
          font-size: 1rem;
        }
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 900px){
    .fulltile{
      img{
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 900px){
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
      "a a a a a a c c c c d d d d"
      "b b b b b b c c c c d d d d"
      "e e e e f f f f g g g g g g"
      "e e e e f f f f h h h h h h"
      "i i i i i k k k k k m m m m"
      "j j j j j l l l l l m m m m";
  }
  @media screen and (max-width: 645px) and (orientation: portrait){
    height: initial;
    padding-bottom: 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g h"
      "i j"
      "k l"
      "m m";
    >div{
      min-height: 175px;
      padding-left: 80px;
      img{
        left: 20px;
        top: 20px;
        height: 40px;
        width: 40px;
      }
    }
    .halftile{
      display: flex;
      padding: 0 10px 0 0;
      flex-direction: column;
      justify-content: end;
      position: relative;
      h3{
        margin-left: 20px;
        margin-bottom: 20px;
      }
    }
    .fulltile{
      img{
        height: 40px;
        width: 40px;
        top: 20px;
        left: 20px;
      }
    }
  }
  @media screen and (max-height: 645px) and (orientation: landscape){
    height: initial;
    padding-bottom: 15px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      "a a a a a a b b b b b b"
      "c c c d d d e e e f f f"
      "c c c d d d e e e f f f"
      "g g g g g g h h h h h h"
      "i i i i i i j j j j j j"
      "k k k k l l l l m m m m"
      "k k k k l l l l m m m m";
      >div{
        padding-left: 80px;
        img{
          left: 20px;
          height: 40px;
          width: 40px;
        }
      }
      .halftile{
        padding-left: 80px;
        min-height: 80px;
        img{
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .fulltile{
        min-height: 175px;
        img{
          top: 20px;
        }
      }
  }
}
@media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
  .home {
    h2{
      margin: 16px 0;
    }
  }
}
</style>